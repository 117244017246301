import React, { useCallback, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';
import { loginModule } from '../LoginPage/LoginDucks';
import {
  clearState, disableFormButton,
  getServiceProvider,
  loadingData,
  searchServiceProvider,
  serviceRequestModule
} from './ServiceRequestDucks';
import PassportProvider from '../../passport/PassportProvider';
import PassportForm from '../../passport/PassportForm';
import { changeFormValue } from '../../passport/Passport';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import paths from '../../_helpers/paths';
import Button from '../../modules/Button/Button';
import PassportExpander from '../../passport/Components/PassportExpander';
import PassportNavigation from '../../passport/Components/PassportNavigation';
import {
  PassportLeftInfo,
  ServiceProviderContainer,
  StyledPassportContainer,
  StyledProviderContainer
} from './ServiceRequestStyles';
import ServiceRequestInfo from './ServiceRequestInfo';
import Row from '../../_ui/Row/Row';
import ServiceProvidersModal from './ServiceProvidersModal/ServiceProvidersModal';
import useSimpleModal from '../../components/_hooks/useSimpleModal';
import { gov_services, services } from '../../_helpers/Constants';
import { extraFunctionality } from './ExtraFunctionality/ExtraFunctionality';
import SignModal from './SignModal/SignModal';
import Loading from './Loading';
import './ServiceRequestTranslate';
import SelectDDOModal from './SelectDDOModal/SelectDDOModal';
import ZeroingQuestionValue from './ExtraFunctionality/ZeroingQuestionValue';
import ServiceRequestFormButton, { switchSPBIN } from './ServiceRequestFormButton';
import { useSelector } from 'react-redux';
import IntegrationModal from './ListChildrenModal/ListChildrenModal';

function switchServiceProviderLabel(metadataKey, t) {
  switch (metadataKey) {
    case services.doc_acceptance_ddo_short.code:
      return t('serviceRequest_po');
    case services.children_property_short.code:
    case services.home_for_champions_short.code:
    case services.government_order_short.code:
    case services.children_adoption_short.code:
    case services.status_assignment_short.code:
    case services.payment_family_short.code:
    case services.payment_patronage_short.code:
      return t('serviceRequest_leb');
    case services.academical_resignations_short.code:
      return t('serviceRequest_otve');
    case services.accept_doc_edu_short.code || services.accept_school_short.code:
      return t('serviceRequest_seo');
    default:
      return t('serviceRequest_serviceProvider');
  }
}

function ServiceRequest(props) {
  const { meta, data, match, loading, user, loadingSave, requester } = props;
  const { loadingData, clearState, getServiceProvider } = props;
  const { id, metadataKey, from } = match.params;
  const { t, i18n } = useTranslation();
  const [serviceProvider, setServiceProvider] = useState(null);
  const [queuedRegion, setQueuedRegion] = useState(null);
  const SPModal = useSimpleModal();
  const selectDDOModal = useSimpleModal();
  const signModal = useSimpleModal();
  const integrationModal = useSimpleModal();
  const dispatch = useDispatch();
  let serviceProviderLabel = switchServiceProviderLabel(metadataKey, t);
  let serviceProviderBin = null;
  const binFunder = useSelector(state => state[serviceRequestModule].bin)

  const checkCode = useCallback(
    (code, value) => {
      if (metadataKey === services.doc_queued_ddo_short.code) {
        if (code === 'request_form_doc_queued_ddo_short.queued_region') {
          setQueuedRegion(value);
        }
        ZeroingQuestionValue.setValue(code, value);
      }
      if (metadataKey === services.home_for_champions_short.code) {
        if (code === 'request_form_home_for_champions_short.home') {
          setQueuedRegion(value);
        }
        ZeroingQuestionValue.setValue(code, value);
      }
      if (metadataKey === services.payment_family_short.code) {
        if (code === 'request_form_payment_family_short.children_region') {
          setQueuedRegion(value);
        }
      }
      if (metadataKey === services.children_property_short.code) {
        if (code === 'request_form_children_property_short.deal_location') {
          setQueuedRegion(value);
        }
      }
    },
    [metadataKey]
  );
  useEffect(() => {
    const typeCode = metadataKey === services.home_for_champions_short.code ? '14' : '13';
    const serviceType = metadataKey === services.home_for_champions_short.code ? 'home_for_champions' : 'doc_queued_ddo';
    searchServiceProvider(queuedRegion, switchSPBIN, setServiceProvider, serviceType, typeCode);
  }, [queuedRegion, metadataKey]);
  
  useEffect(() => () => ZeroingQuestionValue.clearState(), []);

  if (
    [
      services.status_assignment_short.code,
      services.home_for_champions_short.code,
    ].includes(metadataKey)
  ) {
    serviceProviderBin = '130740021549';
  } else if (services.government_order_short.code === metadataKey) {
    serviceProviderBin = '120640016963';
  } else if (
    [
      services.payment_family_short.code,
      services.payment_patronage_short.code,
      services.accept_doc_edu_short.code,
      services.accept_school_short.code,
    ].includes(metadataKey)
  ) {
    serviceProviderBin = true;
  } else if (services.doc_acceptance_ddo_short.code === metadataKey) {
    serviceProviderBin = binFunder
  }

  function serviceProviderHandler(sp) {
    if (metadataKey === services.doc_acceptance_ddo_short.code) {
      changeFormValue(
        `request_form_doc_acceptance_ddo_short.kindergarden_name`,
        sp.ru_name
      );
      changeFormValue(
        `request_form_doc_acceptance_ddo_short.kindergarden_name_kz`,
        sp.kk_name
      );
    } else if (metadataKey === services.academical_resignations_short.code) {
      changeFormValue(
        'request_form_academical_resignations_short.name_oe',
        sp[`${i18n.language}_name`]
      );
      getServiceProvider(sp.id, metadataKey);
    } else if (metadataKey === services.accept_doc_edu_short.code) {
      changeFormValue(
        'request_form_accept_doc_edu_short.School_Name_applic',
        sp[`${i18n.language}_name`]
      );
      changeFormValue('request_form_accept_doc_edu_short.School_code_applic', sp[`id`]);
    } else if (metadataKey === services.accept_school_short.code) {
      changeFormValue(
        'request_form_accept_school_short.School_Name_applic',
        sp[`${i18n.language}_name`]
      );
      changeFormValue('request_form_accept_school_short.School_code_applic', sp[`id`]);
    }
    setServiceProvider(sp);
  }

  const isDisabled = !!from;
  const metadataName = meta && meta[`${i18n.language}_name`];
  const breadCrumbItems = [];
  if (isDisabled) {
    if (`/${from}` === paths.requestsPage) {
      breadCrumbItems.push({ name: t('requests_title'), href: paths.requestsPage });
    } else {
      breadCrumbItems.push({
        name: t('completedRequests_title'),
        href: paths.completedRequestsPage
      });
    }
  } else {
    breadCrumbItems.push({ name: t('home_subtitle'), href: paths.homePage });
    if (!(metadataKey in gov_services)) {
      breadCrumbItems.push({
        name: t('kindergarten_subtitle'),
        href: paths.kindergartenServices
      });
    }
  }
  breadCrumbItems.push({ name: metadataName });

  useEffect(() => {
    if (!isDisabled) {
      window.onbeforeunload = () => t('serviceRequest_onbeforunload');
      return () => (window.onbeforeunload = null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadingData(id, metadataKey);
    return () => clearState();
  }, [id, metadataKey, clearState, loadingData]);

  function extraF(code, value) {
    checkCode(code, value);
    return extraFunctionality({
      code,
      value,
      SPModal,
      requesterIin: user.iin,
      setDisabled: (v) => dispatch(disableFormButton(v)),
      selectDDOModal,
      integrationModal
    });
  }

  return (
    <div className="fullWidth mb3 mt3">
      {user &&
        !isDisabled &&
        !SPModal.isOpen &&
        !selectDDOModal.isOpen &&
        !loadingSave && <Prompt message={() => t('serviceRequest_onbeforunload')} />}
      <BreadCrumbs items={breadCrumbItems} />
      <div
        style={{
          fontSize: '1.2em',
          textAlign: 'center',
          textTransform: 'uppercase',
          margin: '30px 0',
          fontWeight: 'bold'
        }}
      >
        {metadataName}
      </div>

      <StyledProviderContainer>
        <PassportProvider
          config={{
            fullDatePicker: true,
            documentsProps: {
              mimeType: 'image/jpeg,image/png,application/pdf',
              maxSize: 10000000,
            },
          }}
          withoutScroll={false}
          data={data}
          meta={meta}
          questionRender={!isDisabled && extraF}
          children={
            <>
              {isDisabled && <PassportExpander />}
              <StyledPassportContainer>
                {isDisabled && (
                  <PassportLeftInfo>
                    <ServiceRequestInfo />
                    <PassportNavigation />
                  </PassportLeftInfo>
                )}
                <div className="flex-auto">
                  { metadataKey !== services.doc_queued_ddo_short.code && 
                    metadataKey !== services.doc_acceptance_ddo_short.code &&
                    metadataKey !== services.children_property_short.code &&
                    metadataKey !== services.accept_school_short.code &&
                    !isDisabled &&
                    !serviceProviderBin && (
                      <ServiceProviderContainer error={!serviceProvider}>
                        <Row
                          className="flex items-center service-provider"
                          label={
                            <>
                              {serviceProviderLabel}
                              <span id="required">*</span>
                            </>
                          }
                          children={
                            <div id="sp-button">
                              <Button
                                disabled={
                                  metadataKey === services.doc_queued_ddo_short.code
                                }
                                fullWidth
                                color="default"
                                variant="outlined"
                                text={
                                  serviceProvider
                                    ? serviceProvider[`${i18n.language}_name`]
                                    : t('serviceRequest_chooseServiceProvider')
                                }
                                onClick={() => SPModal.open(true)}
                              />
                              {!serviceProvider && (
                                <span id="error">{t('requiredField')}</span>
                              )}
                            </div>
                          }
                        />
                      </ServiceProviderContainer>
                    )}
                  <PassportForm
                    disabled={isDisabled}
                    loading={loading}
                    onLoadGroup={() => {
                      if (services.government_order_short.code === metadataKey) {
                        changeFormValue(
                          `request_form_government_order_short.adress_country`,
                          requester.country
                        );
                        changeFormValue(
                          `request_form_government_order_short.adress_area`,
                          requester.area
                        );
                        changeFormValue(
                          `request_form_government_order_short.adress_region`,
                          requester.region
                        );
                        changeFormValue(
                          `request_form_government_order_short.adress_city`,
                          requester.city
                        );
                        changeFormValue(
                          `request_form_government_order_short.adress_street`,
                          requester.street
                        );
                        changeFormValue(
                          `request_form_government_order_short.adress_house`,
                          requester.house
                        );
                        changeFormValue(
                          `request_form_government_order_short.adress_corpus_number`,
                          requester.corpus_number
                        );
                        changeFormValue(
                          `request_form_government_order_short.adress_flat`,
                          requester.flat
                        );
                      }
                    }}
                    buttonRender={btnLoading =>
                      !isDisabled && (
                        <ServiceRequestFormButton
                          serviceProviderBin={serviceProviderBin}
                          loadingSave={loadingSave}
                          btnLoading={btnLoading}
                          serviceProvider={serviceProvider}
                          metadataKey={metadataKey}
                          signModal={signModal}
                          user={user}
                          data={data}
                        />
                      )
                    }
                  />
                </div>
              </StyledPassportContainer>
            </>
          }
        />
      </StyledProviderContainer>
      {SPModal.isOpen && (
        <ServiceProvidersModal modal={SPModal} setFormData={serviceProviderHandler} />
      )}
      {signModal.isOpen && <SignModal {...signModal} />}
      {selectDDOModal.isOpen && <SelectDDOModal {...selectDDOModal} />}
      {integrationModal.isOpen && <IntegrationModal {...integrationModal} />}
      <Loading />
    </div>
  );
}

export default connect(
  state => ({
    user: state[loginModule].user,
    requester: state[loginModule].requester,
    meta: state[serviceRequestModule].meta,
    data: state[serviceRequestModule].data,
    loading: state[serviceRequestModule].loading,
    loadingSave: state[serviceRequestModule].loadingSave
  }),
  {
    loadingData,
    clearState,
    getServiceProvider
  }
)(ServiceRequest);
