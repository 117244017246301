import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    home_title: 'Портал электронных услуг акимата области Жетісу',
    home_subtitle: 'Предоставляемые услуги',
    home: 'Главная',
    home_support: 'Служба поддержки',
    home_frequently_asked_questions: 'Часто задаваемые вопросы',
    home_user_guide: 'Руководство пользователя',
    home_user_video: 'Видеоинструкция пользователя',

    home_insert_title: 'Получите госуслугу быстро, удобно, легко',
    home_insert_text: 'Экономьте ваше время и нервы',
    home_insert_btn: 'Войти в личный кабинет',
    home_order_service: 'Заказать услугу',
    home_integration_modal_text: 'Для автоматического выбора и заполнения данных ваших детей при подаче государственной услуги будет отправлено SMS-сообщение для получения вашего согласия на сбор и обработку данных. Это необходимо для ускорения процесса подачи услуги.',
    home_agree: 'Я согласен ',
    home_donAgree: 'Я не согласен'
  },
  kk: {
    home_title: 'Жетісу облысы Әкімдігінің Электронды қызметтер Порталы',
    home_subtitle: 'Ұсынылатын қызметтер',
    home: 'Басты бет',
    home_support: 'Қолдау қызметі',
    home_frequently_asked_questions: 'Жиі қойылатын сұрақтар',
    home_user_guide: 'Пайдаланушы нұсқаулығы',
    home_user_video: 'Пайдаланушының бейне нұсқаулығы',

    home_insert_title: 'Мемлекеттік қызметті тез, ыңғайлы әрі оңай алыңыз',
    home_insert_text: 'Уақыт пен жүйкеңізді үнемдеңіз',
    home_insert_btn: 'Жеке кабинетке кіру',
    home_order_service: 'Қызметке тапсырыс беру',
    home_integration_modal_text: 'Мемлекеттік қызметке өтініш беру кезінде балаларыңыздың деректерін автоматты түрде таңдау және толтыру үшін деректерді жинауға және өңдеуге келісім алу мақсатында сізге SMS хабарлама жіберіледі. Бұл өтініш беру процесін жеделдету үшін қажет.',
    home_agree: 'Мен келісемін',
    home_donAgree: 'Мен қарсымын'
  }
});
