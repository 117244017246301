import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    kindergarten_accompaniments_subtitle: 'Сопровождение',
    kindergarten_accompaniments_error_load: 'Ошибка при загрузке уведомлений',
    kindergarten_accompaniments_accompaniment: 'Сопровождающий',
    kindergarten_accompaniments_accompanimentIin: 'ИИН сопровождающего',
    kindergarten_accompaniments_fio_child: 'ФИО ребенка',
    kindergarten_accompaniments_listChildren: 'Список детей',
    kindergarten_accompaniments_addChildren: 'Добавить сопровождающего',
    kindergarten_accompaniments_serviceProviderName: 'Наименование',
    kindergarten_accompaniments_serviceProviderGroup: 'Группа',
    kindergarten_accompaniments_modal_title: 'Добавить сопровождающего',
    kindergarten_accompaniments_addSuccess: 'Успешно сохранено',
    kindergarten_accompaniments_addError: 'Ошибка при сохранение',
    kindergarten_accompaniments_confirmationTitle: 'Подтверждение',
    kindergarten_accompaniments_confirmationText: 'Вы уверены что хотите удалить?',
    kindergarten_accompaniments_deleteSuccess: 'Успешно удаленно',
    kindergarten_accompaniments_errorRemove: 'Не удалось удалить',
    kindergarten_accompaniments_modal_yes: 'Да',
    kindergarten_accompaniments_modal_no: 'Нет',
    kindergarten_accompaniments_accompanyingPersonSameIIN: 'У ребенка уже есть сопровождающий с таким ИИН',
    kindergarten_accompaniments_addYourselfFollower: 'Вы не можете добавить себя как сопровождающий',
    kindergarten_accompaniments_alreadyHas5Children: 'У данного пользователя на сопровождении уже 5 детей.',
  },
  kk: {
    kindergarten_accompaniments_subtitle: 'Ертіп жүру',
    kindergarten_accompaniments_error_load: 'Деректерді жүктеуде қателік орын алды',
    kindergarten_accompaniments_accompaniment: 'Ертіп жүруші',
    kindergarten_accompaniments_accompanimentIin: 'Ертіп жүрушінің ЖНС',
    kindergarten_accompaniments_fio_child: 'Баланың аты-жөні',
    kindergarten_accompaniments_listChildren: 'Балалардың тізімі',
    kindergarten_accompaniments_addChildren: 'Ертіп жүрушіні қосу',
    kindergarten_accompaniments_serviceProviderName: 'Атауы',
    kindergarten_accompaniments_serviceProviderGroup: 'Топы',
    kindergarten_accompaniments_modal_title: 'Ертіп жүрушіні қосу',
    kindergarten_accompaniments_addSuccess: 'Сәтті сақталды',
    kindergarten_accompaniments_addError: 'Сақтау кезінде қате',
    kindergarten_accompaniments_confirmationTitle: 'Растау',
    kindergarten_accompaniments_confirmationText: 'Сіз жойғыңыз келетініне сенімдісіз бе?',
    kindergarten_accompaniments_deleteSuccess: 'Сәтті жойылды',
    kindergarten_accompaniments_errorRemove: 'Жою мүмкін болмады',
    kindergarten_accompaniments_modal_yes: 'Иә',
    kindergarten_accompaniments_modal_no: 'Жоқ',
    kindergarten_accompaniments_accompanyingPersonSameIIN: 'Балада осындай ЖСН-мен ертіп жүруші бар',
    kindergarten_accompaniments_addYourselfFollower: 'Сіз өзіңізді ертіп жүруші ретінде қоса алмайсыз',
    kindergarten_accompaniments_alreadyHas5Children: 'Бұл қолданушыда ертіп жүруде 5 бала бар.',
  }
});
