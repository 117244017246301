import React from 'react';
import { StyledTable } from './TableColumnStyle';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

export const requestsColumns = ({
  t,
  lang,
  modalAdd,
  modalDelete
}) => {
  return [
    {
      Cell: row => (
        <Column
          data={row.original}
          index={row.index}
          t={t}
          lang={lang}
          modalAdd={modalAdd}
          modalDelete={modalDelete}
        />
      )
    }
  ];
};

const Column = ({
  data,
  t,
  lang,
  modalAdd,
  modalDelete
}) => {
  return (
    <StyledTable>
      <div className="row1">
        <div className="col1">
          <div>
            <b>{`${data.surname || ''} ${data.name ||
            ''} ${data.middlename || ''}`}</b>
          </div>
          <div>
            <b>{t('kindergarten_applications_iin_child')}:</b> {data.iin}
          </div>
          <div>
            <b>{t('kindergarten_accompaniments_serviceProviderName')}:</b> {lang === 'ru' ? data.serviceProviderRuName : data.serviceProviderKkName}
          </div>
          <div>
            <b>{t('kindergarten_accompaniments_serviceProviderGroup')}:</b> {data.groupName}
          </div>
          {
            data.attendants.map((itemAttendant, index) => {
              return <div>
                <b>{t('kindergarten_accompaniments_accompaniment')} №{index+1}:</b> {itemAttendant.attendantIin}
                <IconButton
                  style={{ padding: 0 }}
                  onClick={() => {
                    modalDelete.open({ ...itemAttendant, childId: data.childId });
                  }}
                  children={<DeleteIcon />}
                />
              </div>;
            })
          }
        </div>
        <div className="col2">
          <Button
            onClick={() => modalAdd.open(data)}
            variant="contained"
            color="secondary"
            size="small"
          >
            {t('kindergarten_accompaniments_addChildren')}
          </Button>
        </div>
      </div>
    </StyledTable>
  );
};
