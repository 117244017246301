import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './KindergartenAccompanimentsTranslate';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import {
  addByApplicant,
  deleteByApplicant,
  kindergartenAccompanimentsModule,
  loadApplications
} from './KindergartenAccompanimentsDucks';
import useModal from '../../modules/_hooks/useModal';
import DialogsAdd from './Dialogs/DialogsAdd';
import StaticTablePaginated from '../../_ui/Table/StaticTablePaginated';
import { requestsColumns } from './TableColumns/TableColumns';
import i18n from 'i18next';
import { StyledCardsContainer } from '../KindergartenApplications/KindergartenApplicationsStyle';
import DialogsDelete from './Dialogs/DialogsDelete';
import Notice from '../../modules/utils/Notice';

function KindergartenAccompaniments() {
  const { t} = useTranslation();
  const dispatch = useDispatch();
  const data = useSelector(state => state[kindergartenAccompanimentsModule].data);
  const loading = useSelector(state => state[kindergartenAccompanimentsModule].loading);
  const modalAdd = useModal('add');
  const modalDelete = useModal('delete');

  useEffect(() => {
    dispatch(loadApplications())
  }, []);
  const handleDone = async ({iin}) => {
    data.forEach((itemData) => {
      if (itemData.childId === modalAdd.data.childId) {
        const find = itemData.attendants.find(el => el.attendantIin === iin);
        if (!find) {
          dispatch(addByApplicant({contingentId: modalAdd.data.childId, attendantIin: iin}, modalAdd.close))
        } else {
          Notice.error(t('kindergarten_accompaniments_accompanyingPersonSameIIN'));
        }
      }
    })
  };
  const handleDelete = async () => {
    dispatch(deleteByApplicant(modalDelete.data, modalDelete.close))
  };
  return (
    <div className="mb3 mt3" style={{ width: '100%' }}>
      <Typography
        children={t('kindergarten_accompaniments_subtitle')}
        color="secondary"
        variant="h6" style={{paddingLeft:'10px'}}
      />
      <StyledCardsContainer>
        <StaticTablePaginated
          TheadComponent={_ => null}
          data={data}
          loading={loading}
          columns={requestsColumns({
            t,
            lang: i18n.language,
            modalAdd,
            modalDelete
          })}
          filterable={false}
        />
      </StyledCardsContainer>
      <DialogsAdd
        open={modalAdd.isOpen}
        handleClose={modalAdd.close}
        handleDone={handleDone}
        loading={loading}
      />
      <DialogsDelete
        open={modalDelete.isOpen}
        handleClose={modalDelete.close}
        handleDelete={handleDelete}
        loading={loading}
      />
    </div>
  );
}

export default KindergartenAccompaniments;
