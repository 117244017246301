import styled from 'styled-components';

export const StyledTable = styled.div`
  .row1 {
    display: flex;
  }
  .col1 div{
    display: block;
    padding-right: 20px;
  }
  .col1{
    width: 70%;
  }
  .col2{
    width: 30%;
    text-align: center;
    white-space: pre-wrap;
  }
`;
