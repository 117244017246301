import axios from 'axios';

export const KindergartenAccompanimentsApi = {
  getListChildren: filter =>
    axios.get(`/api/public/info/po_children_for_parent`),
  getInfoByChild: (contingentId) =>
    axios.get(`api/public/get_attendant_info_by_child_id?contingentId=${contingentId}`),
  addAccompaniment: (data) =>
    axios.post(`api/public/add_attendant_to_child`, data),
  deleteAccompaniment: (attendantId) =>
    axios.post(`api/public/delete_attendant_to_child?attendantId=${attendantId}`)
};
