import React from 'react';
import { useTranslation } from 'react-i18next';

import Modal from '../../../_ui/Modal/Modal';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { setDonIntegration } from '../../LoginPage/LoginDucks';
import { useDispatch } from 'react-redux';

function IntegrationModal({ close, isOpen, onDonIntegration, onZagsInit, setCheck }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        setCheck(false)
        dispatch(setDonIntegration(true, close));
      }}
      disableBackdropClick={true}
    >
      <DialogContent>
        <div style={{ fontSize: '18px' }}>
          {t('home_integration_modal_text')}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => onDonIntegration()}
          color="primary"
          variant="contained"
        >
          {t('home_donAgree')}

        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => onZagsInit()}
        >
          {t('home_agree')}
        </Button>
      </DialogActions>
    </Modal>
  );
}

export default IntegrationModal;
