import sortReducers from '../modules/utils/sortReducers';
import loginReducer, { loginModule } from '../pages/LoginPage/LoginDucks';
import serviceRequestReducer, {
  serviceRequestModule
} from '../pages/ServiceRequest/ServiceRequestDucks';
import requestsReducer, { requestsModule } from '../pages/Requests/RequestsDucks';
import completedRequestsReducer, {
  completedRequestsModule
} from '../pages/CompletedRequests/CompletedRequestsDucks';
import serviceProvidersReducer, {
  serviceProvidersModule
} from '../pages/ServiceRequest/ServiceProvidersModal/ServiceProvidersDucks';
import accountSettingsReducer, {
  accountSettingsModule
} from '../pages/AccountSettings/AccountSettingsDucks';
import checkQueueNumberReducer, {
  checkQueueNumberModule
} from '../pages/CheckQueueNumber/CheckQueueNumberDucks';
import localeReducer, { localeModule } from '../ducks/Locale';
import queueApplicationsReducer, {
  queueApplicationsModule
} from '../pages/QueueApplications/QueueApplicationsDucks';
import kindergartenReestrReducer, {
  kindergartenReestrModule
} from '../pages/KindergartenReestr/KindergartenReestrDucks';
import KindergartenAppointmentsReducer, {
  KindergartenAppointmentsModule
} from '../pages/KindergartenAppointments/KindergartenAppointmentsDucks';
import kindergartenApplicationsReducer, {
  kindergartenApplicationsModule
} from '../pages/KindergartenApplications/KindergartenApplicationsDucks';
import kindergartenNotificationsReducer, {
  kindergartenNotificationsModule
} from '../pages/KindergartenNotifications/KindergartenNotificationsDucks';
import kindergartenReestrFilterReducer, {
  kindergartenReestrFilterModule
} from '../pages/KindergartenReestr/KindergartenReestrComponents/Filters/FiltersDucks';
import bulletinOpenPlacesReducer, {
  bulletinOpenPlacesModule
} from '../pages/BulletinOpenPlaces/BulletinOpenPlacesDucks';
import bulletinFilterReducer, {
  bulletinFilterModule
} from '../pages/BulletinOpenPlaces/BulletinOpenPlacesComponents/Filters/FiltersDucks';
import selectDDOReducer, {
  selectDDoModule
} from '../pages/ServiceRequest/SelectDDOModal/SelectDDODucks';
import kindergartenCountersReducer, {
  kindergartenCountersModule
} from '../pages/KindergartenReestr/KindergartenReestrComponents/Counters/CountersDucks';
import kindergartenReestrViewReducer, {
  kindergartenReestrViewModule
} from '../pages/KindergartenReestr/KindergartenReestrView/KindergartenReestrViewDucks';
import queueAppsFilterReducer, { queueAppsFilterModule } from '../pages/QueueApplications/QueueApplicationsComponents/Filters/FiltersDucks';
import headerReducer, { headerModule } from '../components/Header/HeaderDucks';
import kindergartenAccompanimentsReducer, {
  kindergartenAccompanimentsModule
} from '../pages/KindergartenAccompaniments/KindergartenAccompanimentsDucks';

export default sortReducers({
  [loginModule]: loginReducer,
  [serviceRequestModule]: serviceRequestReducer,
  [requestsModule]: requestsReducer,
  [completedRequestsModule]: completedRequestsReducer,
  [serviceProvidersModule]: serviceProvidersReducer,
  [accountSettingsModule]: accountSettingsReducer,
  [checkQueueNumberModule]: checkQueueNumberReducer,
  [localeModule]: localeReducer,
  [queueApplicationsModule]: queueApplicationsReducer,
  [kindergartenReestrModule]: kindergartenReestrReducer,
  [KindergartenAppointmentsModule]: KindergartenAppointmentsReducer,
  [kindergartenApplicationsModule]: kindergartenApplicationsReducer,
  [kindergartenNotificationsModule]: kindergartenNotificationsReducer,
  [kindergartenReestrFilterModule]: kindergartenReestrFilterReducer,
  [bulletinOpenPlacesModule]: bulletinOpenPlacesReducer,
  [bulletinFilterModule]: bulletinFilterReducer,
  [selectDDoModule]: selectDDOReducer,
  [kindergartenCountersModule]: kindergartenCountersReducer,
  [kindergartenReestrViewModule]: kindergartenReestrViewReducer,
  [queueAppsFilterModule]: queueAppsFilterReducer,
  [headerModule]: headerReducer,
  [kindergartenAccompanimentsModule]: kindergartenAccompanimentsReducer
});
