import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  StyledServicesCard,
  StyledServicesCardImg,
  StyledServicesCardText,
  StyledServicesCardBlock, StyledServicesCardNew
} from './HomeSectionStyles';
import { useDispatch, useSelector } from 'react-redux';
import useUserInfo from '../../../components/_hooks/useUserInfo';
import {
  loginModalHandler,
  loginModule,
  setDonIntegration,
  zagsInit
} from '../../LoginPage/LoginDucks';
import { checkAuth } from '../../../utils/needAuth';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/styles';
import { history } from '../../../_helpers/history';
import { services } from '../../../_helpers/Constants';
import Notice from '../../../modules/utils/Notice';
import useSimpleModal from '../../../components/_hooks/useSimpleModal';
import IntegrationModal from '../Dialogs/IntegrationModal';

const ColorButton = withStyles(() => ({
  root: {
    padding: '10px 20px',
    fontWeight: '700',
    fontSize: '14px',
    textTransform: 'uppercase',
    letterSpacing: '0.01em',
    fontFamily: 'Montserrat',
    ['@media (max-width:1000px)']: {
      padding: '15px 30px'
    }
  }
}))(Button);

function HomeServicesCards({ metadata }) {
  const { t, i18n } = useTranslation();
  const integrationModal = useSimpleModal();
  const { isIntegration, donIntegration} = useSelector(state => state[loginModule]);
  const [loginModal, setLoginModal] = useState(true)
  const [check, setCheck] = useState(false);
  const dispatch = useDispatch();
  const user = useUserInfo();
  const needAuth = checkAuth(metadata.url);
  const onDonIntegration = () => {
    dispatch(setDonIntegration(true, integrationModal.close))
    onOpenPassport();
  }
  const onZagsInit = () => {
    dispatch(zagsInit({ close: integrationModal.close }))
    onOpenPassport();
  }
  const onOpenPassport = () => {
    if (loginModal) {
      if (
        [
          services.government_order_short.code,
          services.status_assignment_short.code
        ].includes(metadata.code) && !user.bin
      ) {
        return Notice.info(t('serviceCard_forLegalPerson'));
      }
      if (!metadata.url) {
        history.push(`/services/create/${metadata.code}`);
      } else {
        history.push(metadata.url);
      }
    } else {
      if (metadata.code === 'doc_queued_ddo_short') {
        history.push(metadata.url);
      } else {
        dispatch(loginModalHandler(true, metadata));
      }
    }
  }

  return (
    <StyledServicesCard>
      {
        metadata?.new && <StyledServicesCardNew>new</StyledServicesCardNew>
      }
      <StyledServicesCardImg>
        <img src={metadata[`img`]} alt={metadata[`${i18n.language}_name`]} />
      </StyledServicesCardImg>
      <StyledServicesCardBlock>
        <StyledServicesCardText children={metadata[`${i18n.language}_name`]} />
        <div>
          {
            (user.username || !needAuth) ?
              <ColorButton
                variant='contained'
                color='secondary'
                onClick={() => {
                  if ((!isIntegration && donIntegration) && !check) {
                    setCheck(true)
                    return integrationModal.open({})
                  }
                  if (
                    [
                      services.government_order_short.code,
                      services.status_assignment_short.code
                    ].includes(metadata.code) && !user.bin
                  ) {
                    return Notice.info(t('serviceCard_forLegalPerson'));
                  }
                  if (!metadata.url) {
                    history.push(`/services/create/${metadata.code}`);
                  } else {
                    history.push(metadata.url);
                  }
                }}
                children={t('home_order_service')}
              />
              :
              <ColorButton
                variant='contained'
                color='secondary'
                onClick={() => {
                  setLoginModal(false);
                  setCheck(true)
                  if ((!isIntegration && donIntegration) && !check) {
                    return integrationModal.open({})
                  }
                  if (metadata.code === 'doc_queued_ddo_short') {
                    history.push(metadata.url);
                  } else {
                    dispatch(loginModalHandler(true, metadata));
                  }
                }}
                children={t('home_order_service')}
              />
          }
        </div>

      </StyledServicesCardBlock>
      {integrationModal.isOpen && <IntegrationModal {...integrationModal} onDonIntegration={onDonIntegration} onZagsInit={onZagsInit} setCheck={setCheck}/>}
    </StyledServicesCard>
  );
}

export default HomeServicesCards;
