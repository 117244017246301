import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { DialogContentText, DialogTitle } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

export default function DialogsDelete({ open, handleClose, handleDelete, loading }) {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t('kindergarten_accompaniments_confirmationTitle')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t('kindergarten_accompaniments_confirmationText')}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ textAlign: "center" }}>
        <div style={{ width: "50%" }}>
          <Button
            onClick={handleClose}
            color="primary"
            variant="contained"
            disabled={loading}
          >{t('kindergarten_accompaniments_modal_no')}</Button>
        </div>
        <div style={{ width: "50%" }}>
          <Button
            color="secondary"
            variant="contained"
            onClick={handleDelete}
            disabled={loading}
          >{t('kindergarten_accompaniments_modal_yes')}</Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}
