import React from 'react';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import EmailIcon from '@material-ui/icons/Email';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import paths from '../../../_helpers/paths';

export const sideBarItems = [
  {
    translate: 'sidebar_requests',
    path: paths.requestsPage,
    icon: <AssignmentIcon fontSize="small" />,
  },
  {
    translate: 'sidebar_completedRequests',
    path: paths.completedRequestsPage,
    icon: <AssignmentTurnedInIcon fontSize="small" />,
  },
];

export const sideBarQueueItems = [
  {
    translate: 'sidebar_queue_applications',
    path: paths.kindergartenApplications,
    icon: <FileCopyIcon fontSize="small" />,
  },
  {
    translate: 'sidebar_queue_appointments',
    path: paths.kindergartenAppointments,
    icon: <InsertDriveFileIcon fontSize="small" />,
  },
  {
    counter: 'notif_counter',
    translate: 'sidebar_queue_notifications',
    path: paths.kindergartenNotifications,
    icon: <EmailIcon fontSize="small" />,
  },
  {
    translate: 'sidebar_queue_accompaniment',
    path: paths.kindergartenAccompaniments,
    icon: <PeopleAltIcon fontSize="small" />,
  },
];
