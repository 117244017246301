const paths = {
  homePage: '/',
  kindergartenServices: '/kindergarten-services',
  govServices: '/gov-services',
  checkQueueNumber: '/check-queue-number',
  queueApplications: '/queue-applications',
  bulletinOpenPlaces: '/bulletin-open-places',
  bulletinOpenPlacesKqId: '/bulletin-open-places/:KqId',
  kindergartenReestr: '/kindergarten-reestr',
  kindergartenReestrById: '/kindergarten-reestr/:id',
  createRequestPage: '/services/create/:metadataKey',
  accountPage: '/account',
  requestsPage: '/requests',
  completedRequestsPage: '/completed-requests',
  accountSettingsPage: '/account/settings',
  viewRequestFormPage: '/view/:from/:id',
  instructionsPage: '/instructions',
  videoInstructionsPage: '/video-instructions',
  faqPage: '/faq',
  kindergartenApplications: '/kindergarten-applications',
  kindergartenAppointments: '/kindergarten-appointments',
  kindergartenNotifications: '/kindergarten-notifications',
  kindergartenAccompaniments: '/kindergarten-accompaniments',
  marketAndroidPage: '/market/android',
};

export default paths;
