import { createReducer } from 'redux-starter-kit';
import { handleError } from '../../modules/utils/handleError';
import i18next from 'i18next';
import { KindergartenAccompanimentsApi } from './KindergartenAccompanimentsApi';
import Notice from '../../modules/utils/Notice';

/**
 * Constants
 */

export const kindergartenAccompanimentsModule = 'kindergartenAccompaniments';
const SET_DATA = `${kindergartenAccompanimentsModule}/SET_DATA`;
const LOADING = `${kindergartenAccompanimentsModule}/LOADING`;

/**
 * Reducer
 */

const initialState = {
  loading: false,
  data: [],
};

export default createReducer(initialState, {
  [SET_DATA]: (state, action) => {
    state.data = action.payload;
  },
  [LOADING]: (state, action) => {
    state.loading = action.loading;
  },
});

export const loadApplications = () => async dispatch => {
  try {
    dispatch({ type: LOADING, loading: true });
    const { data } = await KindergartenAccompanimentsApi.getListChildren();
    if (data.status === "SUCCESS") {
      for (let itemResult of data.result) {
        if (itemResult.childId) {
          itemResult['attendants'] = (await KindergartenAccompanimentsApi.getInfoByChild(itemResult.childId)).data.result
        }
      }
      dispatch({
        type: SET_DATA,
        payload: data.result
      })
    }
  } catch (error) {
    handleError(error, i18next.t('kindergarten_accompaniments_error_load'));
  } finally {
    dispatch({ type: LOADING, loading: false });
  }
};

export const addByApplicant = (info, close) => async (dispatch, getState) => {
  try {
    dispatch({ type: LOADING, loading: true });
    const { data } = await KindergartenAccompanimentsApi.addAccompaniment(info)
    const state = getState()[kindergartenAccompanimentsModule];
    const stateCopy = JSON.parse(JSON.stringify(state.data));
    if (data.status === "SUCCESS") {
      stateCopy.forEach((itemChild) => {
        if (itemChild.childId === info.contingentId) {
          itemChild.attendants = [...itemChild.attendants, data.result]
        }
      })
      dispatch({
        type: SET_DATA,
        payload: stateCopy
      })
      close()
      Notice.success(i18next.t('kindergarten_accompaniments_addSuccess'));
    }
  } catch (error) {
    if (error?.response?.data?.errors[0]?.code === 'error.attendant_has_max_children') {
      Notice.error(i18next.t('kindergarten_accompaniments_alreadyHas5Children'))
    } else if (error?.response?.data?.errors[0]?.code === 'error.self_iin_cannot_be_attendant_for_child') {
      Notice.error(i18next.t('kindergarten_accompaniments_addYourselfFollower'))
    } else {
      handleError(error, i18next.t('kindergarten_accompaniments_addError'));
    }
  } finally {
    dispatch({ type: LOADING, loading: false });
  }
};

export const deleteByApplicant = (info, close) => async (dispatch, getState) => {
  try {
    dispatch({ type: LOADING, loading: true });
    const { data } = await KindergartenAccompanimentsApi.deleteAccompaniment(info.id);
    const state = getState()[kindergartenAccompanimentsModule];
    const stateCopy = JSON.parse(JSON.stringify(state.data));
    if (data.status === "SUCCESS") {
      stateCopy.forEach((itemChild) => {
        if (itemChild.childId === info.childId) {
          itemChild.attendants = itemChild.attendants.filter((itemAttendant) => itemAttendant.id !== info.id)
        }
      })
      dispatch({
        type: SET_DATA,
        payload: stateCopy
      })
      close()
      Notice.success(i18next.t('kindergarten_accompaniments_deleteSuccess'));
    }
  } catch (error) {
    handleError(error, i18next.t('kindergarten_accompaniments_errorRemove'));
  } finally {
    dispatch({ type: LOADING, loading: false });
  }
};
