import React, { useEffect, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import NotificationContainer from 'react-notifications-component';

import ProtectedRoute from './ProtectedRoute';
import Page404 from '../pages/Page404/Page404';
import routerProps, { sideBarPaths } from '../_helpers/routerProps';
import Header from '../components/Header/Header';
import { Content } from '../components/ThemeProvider/ThemeStyle';
import Footer from '../components/Footer/Footer';
import SideBar from '../components/Header/SideBar/SideBar';
import { checkLogin, loginModule } from '../pages/LoginPage/LoginDucks';
import paths from '../_helpers/paths';
import { history } from '../_helpers/history';
import TokenRefresher from '../components/TokenRefresher/TokenRefresher';
import Login from '../pages/LoginPage/Login';
import { getContentWidth } from '../utils/getContentWidth';
import HeadTitle from '../components/Head/HeadTitle'

function Root({ checkLogin, requester, ...props }) {
  let [isCurrent, setIsCurrent] = React.useState(false)
  if (requester && props.location.pathname !== paths.accountSettingsPage) {
    if (!requester['user_email'] || !requester['user_telephone_number']) {
      history.push(paths.accountSettingsPage);
    } 
  }
  useEffect(() => {
    checkLogin();
  }, [checkLogin]);

  React.useEffect(() => {
    if (paths.kindergartenServices === props.location.pathname || paths.homePage  === props.location.pathname) {
      setIsCurrent(true)
    } else {
      setIsCurrent(false)
    }
  }, [props.location.pathname])

  const contentWidth = getContentWidth(props.location.pathname);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [props.location.pathname]);
  
  return (
    <>
      <NotificationContainer className="left-0 top-0" />
      {props.user && <TokenRefresher />}
      <Login />
      <HeadTitle />
      <Header contentWidth = {contentWidth} isCurrent = {isCurrent}/>
      <Switch>
        <Content contentWidth={contentWidth} className = {isCurrent && 'kindergartenServices'}>
          <Route exact path={sideBarPaths} component={SideBar} />
          <ProtectedRoute exact {...props} {...routerProps.homePage} />
          <ProtectedRoute exact {...props} {...routerProps.marketAndroidPage} />
          <ProtectedRoute exact {...props} {...routerProps.kindergartenServices} />
          <ProtectedRoute exact {...props} {...routerProps.govServices} />
          <ProtectedRoute exact {...props} {...routerProps.checkQueueNumber} />
          <ProtectedRoute exact {...props} {...routerProps.queueApplications} />
          <ProtectedRoute exact {...props} {...routerProps.bulletinOpenPlaces} />
          <ProtectedRoute exact {...props} {...routerProps.bulletinOpenPlacesKqId} />
          <ProtectedRoute exact {...props} {...routerProps.kindergartenReestr} />
          <ProtectedRoute exact {...props} {...routerProps.kindergartenReestrById} />
          <ProtectedRoute exact {...props} {...routerProps.createRequestPage} />
          <ProtectedRoute exact {...props} {...routerProps.accountPage} />
          <ProtectedRoute exact {...props} {...routerProps.requestsPage} />
          <ProtectedRoute exact {...props} {...routerProps.completedRequestsPage} />
          <ProtectedRoute exact {...props} {...routerProps.accountSettingsPage} />
          <ProtectedRoute exact {...props} {...routerProps.viewRequestFormPage} />
          <ProtectedRoute exact {...props} {...routerProps.instructionsPage} />
          <ProtectedRoute exact {...props} {...routerProps.videoInstructionsPage} />
          <ProtectedRoute exact {...props} {...routerProps.faqPage} />
          <ProtectedRoute exact {...props} {...routerProps.kindergartenApplications} />
          <ProtectedRoute exact {...props} {...routerProps.kindergartenAppointments} />
          <ProtectedRoute exact {...props} {...routerProps.kindergartenNotifications} />
          <ProtectedRoute exact {...props} {...routerProps.kindergartenAccompaniments} />
        </Content>
        <Route component={Page404} />
      </Switch>
      <Footer contentWidth = {contentWidth}/>
    </>
  );
}

export default connect(
  state => ({
    user: state[loginModule].user,
    requester: state[loginModule].requester,
    userPermissions: state[loginModule].permissions,
    loading: state[loginModule].loading
  }),
  { checkLogin }
)(withRouter(Root));
