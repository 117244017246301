import axios from 'axios';

export const LoginApi = {
  login: credentials =>
    axios.post('/login', credentials),

  logout: (accessToken) => axios.get('/logout', {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  }),

  getStringToSign: () => axios.get('/login/signature'),

  loginWithSign: data => axios.post('/login/signature', data),

  getUser: () => axios.get('/login'),

  getRequesterData: () => axios.get(
    `/api/self/requester`
  ),
  getCheckZagsToken: () => axios.get(`api/public/integration/check_zags_token`),
  zagsInit: () => axios.post(`api/public/integration/zags_init`),
};
