import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../_ui/Modal/Modal';
import { ServiceRequestApi } from '../ServiceRequestApi';
import { changeFormValue } from '../../../passport/Passport';
import { handleError } from '../../../modules/utils/handleError';
import DialogContent from '@material-ui/core/DialogContent';
import { Table } from '../ServiceProvidersModal/ServiceProvidersModalStyles';
import Notice from '../../../modules/utils/Notice';

function ListChildrenModal({ close, isOpen, data }) {
  const metadataKey = data.metadataKey.split('.')[0]
  const { t } = useTranslation();
  const [ListChildren, setListChildren] = useState([]);
  const [loading, setLoading] = useState(false);
  const requestGBDFL = async () => {
    try {
      setLoading(true);
      const check = await ServiceRequestApi.checkZagsIntegration();
      if (!check.data.result.isActive) {
        const { data } = await ServiceRequestApi.getChildrenFromZags();
        if (data.status === 'SUCCESS') {
          setListChildren(data.result)
        }
      } else {
        Notice.warning(t('serviceRequest_pendingCollectionProcessing'))
        setTimeout(() => requestGBDFL(), 10000)
      }
    } catch (error) {
      handleError(error, t('serviceProviders_exitedErrorListChildren'))
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    requestGBDFL()
  }, []);
  const selectChildren = (children) => {
    if ('request_form_doc_queued_ddo_short' === metadataKey) {
      changeFormValue(`${metadataKey}.child_iin`, children['iin']);
    } else {
      changeFormValue(`${metadataKey}.Child_iin`, children['iin']);
    }
    changeFormValue(`${metadataKey}.child_surname`, children['lastname']);
    changeFormValue(`${metadataKey}.child_name`, children['firstname']);
    changeFormValue(`${metadataKey}.child_middlename`, children['middlename']);
    changeFormValue(`${metadataKey}.child_birthday`, children['birthday']);
    close()
  }
  return (
    <Modal
      open={isOpen}
      onClose={close}
      title={t('serviceRequest_listChildren')}
    >
      <DialogContent dividers>
      <Table
        loading={loading}
        columns={[
          {
            Header: t('serviceProviders_fioChild'),
            filterable: false,
            Cell: ({ original }) => original.lastname + ' ' + original.firstname + ' ' + original.middlename,
          },
          {
            Header: t('serviceProviders_columnIin'),
            filterable: false,
            width: 150,
            Cell: ({ original }) => original.iin,
          }
        ]}
        onClickRow={({ original }) => selectChildren(original)}
        data={ListChildren}
      />
    </DialogContent>
    </Modal>
  );
}

export default ListChildrenModal;
