import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { DialogTitle, TextField } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useHelperTextStyles = makeStyles(() => ({
  root: {
    marginTop: '10px',
    textTransform: 'none'
  }
}));
export default function DialogsAdd({ open, handleClose, handleDone, loading }) {
  const { t } = useTranslation();
  const form = useForm({
    mode: 'all',
    shouldUnregister: false,
  });
  const helperTextStyles = useHelperTextStyles();
  const onSubmit = (data) => {
    handleDone({ iin: data.iin })
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <form onSubmit={form.handleSubmit(onSubmit)}>

        <DialogTitle id="alert-dialog-title">
          {t('kindergarten_accompaniments_modal_title')}
        </DialogTitle>
        <DialogContent>
          <Controller
            name="iin"
            control={form.control}
            defaultValue=''
            rules={{
              required: t('check_queue_requiredField'),
              maxLength: { value: 12, message: t('check_queue_correctData'), },
              minLength: { value: 12, message: t('check_queue_correctData'), },
              pattern: {
                value: /^[0-9]+$/,
                message: t('check_queue_correctData'),
              },
            }}
            as={
              <TextField
                id="outlined-basic"
                label={t('kindergarten_accompaniments_accompanimentIin')}
                variant="outlined"
                style={{ width: '370px' }}
                error={!!form.errors?.iin}
                helperText={form.errors?.iin?.message}
                FormHelperTextProps={{
                  classes: {
                    root: helperTextStyles.root
                  }
                }}
              />
            }
          />
        </DialogContent>
        <DialogActions style={{ textAlign: "center" }}>
          <div style={{ width: "50%" }}>
            <Button
              onClick={handleClose}
              color="primary"
              variant="contained"
              disabled={loading}
            >{t('kindergarten_accompaniments_modal_no')}</Button>
          </div>
          <div style={{ width: "50%" }}>
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              disabled={loading}
            >{t('kindergarten_accompaniments_modal_yes')}</Button>
          </div>
        </DialogActions>
      </form>
    </Dialog>
);
}
