import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    serviceRequest_number: 'Номер заявки',
    serviceRequest_status: 'Статус заявки',
    serviceRequest_processing_result: 'Результат обработки',
    serviceRequest_date: 'Дата заявки',
    serviceRequest_iin: 'ИИН заявителя',
    serviceRequest_PEP_number: 'Номер заявки ПЭП',
    serviceRequest_providerId: 'Номер услугодателя',
    serviceRequest_providerBin: 'БИН услугодателя',
    serviceRequest_serviceProvider: 'Услугодатель',
    serviceRequest_chooseServiceProvider: 'Выбрать',
    gbdflButton_searchFailed: 'Ошибка сервера',
    gbdflButton_iinNotFound: 'Данных по введенному ИИН не найдены в Государственной базе данных физических лиц, проверьте корректность ввода ИИН или обратитесь в государственный орган.',
    gbdflButton_iinNotFound_2: 'В Государственной базе данных физических лиц данные не найдены. Проверьте корректность ввода ИИН или приложите подтверждающий документ.',
    gbdflButton_iinNotFound_3: 'По указанному ИИН ребенка в системе не были найдены данные по выданному направлению в дошкольную организацию. Вам необходимо в личном кабинете получить «Направление» в дошкольную организацию',
    gbdflButton_iinNotFound_4: 'По данному ИИН ребенка направление в дошкольную организацию было получено другим Заявителем. Необходимо подать заявление на зачисление ребенка от Заявителя, который получил направление в дошкольную организацию.',
    gbdflButton_iinNotFound_5: 'Данные не найдены. Проверьте корректность ввода ИИН или заполните все обязательные поля.',
    gbdflButton_binNotFound: 'Данных по запрашиваемому БИН не найдены',
    gbdflButton_search: 'Запрос данных',
    serviceRequest_onbeforunload: 'Вы хотите покинуть страницу?',
    serviceRequest_fillChildIin: 'Необходимо заполнить ИИН ребенка!',
    serviceRequest_mustBeSixYearsOld: 'Возраст ребенка должен быть 6 лет на конец текущего года',
    serviceRequest_alreadyStudy: 'Поступающий числится в организации образования {{schoolName}}, воспользуйтесь типом заявления "Прием документов для перевода детей между организациями начального, основного среднего, общего среднего образования"',
    serviceRequest_po: 'Дошкольные организации',
    serviceRequest_leb: 'Местный исполнительный орган',
    serviceRequest_otve: 'Организации технического и профессионального образования',
    serviceRequest_seo: 'Организации среднего образования',
    serviceRequest_selectDDO: 'Выбрать',

    serviceRequestDucks_saveSuccess: 'Ваша заявка успешно подписана',
    serviceRequestDucks_signCanceled: 'Подпись заявки отменена',
    serviceRequestDucks_saveFailed: 'Не удалось подписать заявку',
    serviceRequestDucks_loadDataFailed: 'Не удалось загрузить данные',
    serviceRequest_age_error: 'В связи с достижением возраста ребенка 6 лет в текущем учебном году получение услуги невозможно',
    serviceRequest_queue_type_check_req_err: 'Ошибка запроса проверки иин ребенка в очереди',
    serviceRequest_check_in_queue_err1: 'Оказание государственной услуги невозможно, т.к. ранее по {{fio}}, ИИН {{child_iin}} подано заявление №{{req_form_id}} (статус "{{app_status}}").',
    serviceRequest_check_in_queue_err2: 'Оказание государственной услуги невозможно, т.к. {{fio}}, ИИН {{child_iin}} по заявлению №{{req_form_id}} стоит в очереди (тип очереди – {{queued_type}}, приоритетность в очереди – {{priority_queued}}).',
    serviceRequest_check_in_queue_err3: 'Оказание государственной услуги невозможно, т.к. {{fio}}, ИИН {{child_iin}} по заявлению №{{req_form_id}} получил(-а) направление №{{dir_id}} действующее сроком до {{dir_date}} г.',
    serviceRequest_check_in_queue_err4: 'Оказание государственной услуги невозможно, т.к. заявление № {{req_form_id}} находится в стоп-листе сроком до {{deadline_stop_list}} г. После завершения указанного срока {{fio}}, ИИН {{child_iin}} будет восстановлен(-а) в очередь.',
  
    childInformationButton_iinNotFound: 'Данные не найдены. Проверьте корректность ввода ИИН или заполните все обязательные поля и приложите сканированный документ свидетельства о рождении.',
    childInformationButton_arrivedDO: 'Оказание государственной услуги невозможно, т.к. {{fio}}, {{child_iin}} получил направление',
    childInformationButton_enrolledDO: 'Оказание государственной услуги невозможно, т.к. {{fio}}, {{child_iin}} зачислен в дошкольную организацию',
    serviceRequest_listChildren: 'Список детей',
    serviceRequest_pendingCollectionProcessing: 'В ожидании согласия на сбор и обработку данных',
  },
  kk: {
    serviceRequest_number: 'Номер заявки',
    serviceRequest_status: 'Статус заявки',
    serviceRequest_processing_result: 'Результат обработки',
    serviceRequest_date: 'Дата заявки',
    serviceRequest_iin: 'ИИН заявителя',
    serviceRequest_PEP_number: 'Номер заявки ПЭП',
    serviceRequest_providerId: 'Номер услугодателя',
    serviceRequest_providerBin: 'БИН услугодателя',
    serviceRequest_serviceProvider: 'Услугодатель',
    serviceRequest_chooseServiceProvider: 'Таңдау',
    gbdflButton_searchFailed: 'Ошибка сервера',
    gbdflButton_iinNotFound: 'Енгізілген ЖСН бойынша деректер Жеке тұлғалардың мемлекеттік деректер базасында табылған жоқ, ЖСН дұрыс енгізілгеніне көз жеткізіңіз немесе мемлекеттік органға жүгініңіз.',
    gbdflButton_iinNotFound_2: 'Жеке тұлғалардың мемлекеттік деректер базасында деректер табылған жоқ. ЖСН дұрыс енгізілгеніне көз жеткізіңіз немесе растайтын құжатты тіркеңіз',
    gbdflButton_iinNotFound_3: 'Баланың көрсетілген ЖСН бойынша жүйе мектепке дейінгі ұйымға берілген жолдама туралы мәліметтерді таппады. Сізге жеке кабинетке кіріп, мектепке дейінгі ұйымға «Жолдаманы» алу қажет.',
    gbdflButton_iinNotFound_4: 'Көрсетілген ЖСН бойынша мектепке дейінгі ұйымға жолдаманы басқа Өтініш беруші алған. Мектепке дейінгі ұйымға жолдама алған Өтініш беруші ғана баланы тіркеуге өтініш бере алады.',
    gbdflButton_iinNotFound_5: 'Деректер табылған жоқ. ЖСН дұрыс енгізілгеніне көз жеткізіңіз немесе міндетті өрістерді толтырыңыз.',
    gbdflButton_binNotFound: 'Данных по запрашиваемому БИН не найдены',
    gbdflButton_search: 'Запрос данных',
    serviceRequest_onbeforunload: 'Сіз парақтан кеткіңіз келе ме?',
    serviceRequest_fillChildIin: 'Необходимо заполнить ИИН ребенка!',
    serviceRequest_mustBeSixYearsOld: 'Жылдың соңында бала 6 жаста болуы керек',
    serviceRequest_alreadyStudy: 'Оқуға түсуші {{schoolName}} білім беру ұйымында есепте тұр, "Балаларды жалпы білім беретін оқу орындары арасында ауыстыру үшін құжаттарды қабылдау" өтініш түрін таңдаңыз"',
    serviceRequest_po: 'Дошкольные организации',
    serviceRequest_leb: 'Местный исполнительный орган',
    serviceRequest_otve: 'Техникалық және кәсіптік білім беру ұйымдары',
    serviceRequest_seo: 'Организации среднего образования',
    serviceRequest_selectDDO: 'Таңдау',

    serviceRequestDucks_saveSuccess: 'Сіздің өтінішіңізге қол қойылды',
    serviceRequestDucks_signCanceled: 'Подпись заявки отменена',
    serviceRequestDucks_saveFailed: 'Не удалось подписать заявку',
    serviceRequestDucks_loadDataFailed: 'Не удалось загрузить данные',
    serviceRequest_age_error: 'Ағымдағы оқу жылында баланың 6 жасқа толуына байланысты қызметті алу мүмкін емес',
    serviceRequest_queue_type_check_req_err: 'Ошибка запроса проверки иин ребенка в очереди',
    serviceRequest_check_in_queue_err1: 'Мемлекеттік қызметті көрсету мүмкін емес, себебі бұрын {{fio}}, ЖСН {{child_iin}} бойынша №{{req_form_id}} өтініш берілген (мәртебесі "{{app_status}}").',
    serviceRequest_check_in_queue_err2: 'Мемлекеттік қызметті көрсету мүмкін емес, себебі {{fio}}, ЖСН {{child_iin}}  №{{req_form_id}} өтініш бойынша кезекте тұр (кезек түрі – {{queued_type}}, кезектегі басымдық – {{priority_queued}}).',
    serviceRequest_check_in_queue_err3: 'Мемлекеттік қызметті көрсету мүмкін емес, себебі {{fio}}, ЖСН {{child_iin}}  №{{req_form_id}} өтініш бойынша {{dir_date}} жылға дейін жарамды №{{dir_id}} жолдама алды.',
    serviceRequest_check_in_queue_err4: 'Мемлекеттік қызметті көрсету мүмкін емес, себебі №{{req_form_id}} өтініш {{deadline_stop_list}} ж. дейін тоқтату парағына жіберілді. Көрсетілген мерзім аяқталғаннан кейін {{fio}}, ЖСН {{child_iin}} кезекте қалпына келтірілетін болады.',
  
    childInformationButton_iinNotFound: 'Деректер табылған жоқ. ЖСН дұрыс енгізілгеніне көз жеткізіңіз немесе міндетті өрістерді толтырыңыз және туу туралы куәліктің сканерленген көшірмесін қоса тіркеңіз.',
    childInformationButton_arrivedDO: 'Мемлекеттік қызметті көрсету мүмкін емес, себебі {{fio}}, {{child_iin}} мектепке дейінгі ұйымға жолдама алды',
    childInformationButton_enrolledDO: 'Мемлекеттік қызметті көрсету мүмкін емес, себебі {{fio}}, {{child_iin}} мектепке дейінгі ұйымда тіркелген',
    serviceRequest_listChildren: 'Список детей',
    serviceRequest_pendingCollectionProcessing: 'Деректерді жинауға және өңдеуге рұқсат күтілуде',
  }
});
